import Vue from 'vue'
import Router from 'vue-router'
import Permisos from '@/router/permisos'

const DefaultContainer = () => import('@/components/containers/DefaultContainer')
const ViewStaffDashboard = () => import('./staff/dashboard')
const ViewStaffPriceListSuppliers = () => import('./staff/crudPriceListSuppliers')

Vue.use(Router)

const router =
  [
    {
      path: '/st/costs',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'CostsStaffDashboard',
          component: ViewStaffDashboard,
          beforeEnter: Permisos.IS_PERSONAL,
        },
        {
          path: 'price-list-suppliers',
          name: 'CostsStaffPriceListSuppliers',
          component: ViewStaffPriceListSuppliers,
          beforeEnter: Permisos.IS_PERSONAL,
        },        
      ]
    },

    {
      path: '/cu/costs',
      component: DefaultContainer,
      children: [

      ]
    },

    {
      path: '/su/costs',
      component: DefaultContainer,
      children: [

      ]
    },
  ]

export default router
